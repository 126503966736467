@use "sass:math";
$fontSize: 8rem;
$mobileFontSize: 4rem;

#home {
    background: transparent;
    perspective: 800px;
    overflow: hidden;
}

#banner {
    .sagarsys {
        font-family: 'neuropol_nova_cnd', sans-serif;
        position: absolute;
        font-size: $fontSize;
        color: rgb(255, 87, 34);
        line-height: $fontSize;
        opacity: 0;
        transform: translate(-50%, -50%);
        mix-blend-mode: screen;

        @media screen and (max-width: 650px) {
            font-size: $mobileFontSize;
            line-height: $mobileFontSize;
        }

        @for $i from 0 through 100 {
            $key: $i + 1;
            &:nth-child(#{$key}) {
                $row: math.floor(math.div($i, 20));
                color: rgb(255, 87, 34);
                clip-path: polygon(
                    math.floor(math.div($i, 2)) * 10% - $row * 100% $row * 50%,
                    math.floor(math.div($key, 2)) *
                        10% -
                        $row *
                        100%
                        math.ceil($key % 2) *
                        50% +
                        ($row * 50%),
                    math.ceil(math.div($key, 2)) * 10% - $row * 100% ($row + 1) * 50%
                );
                transform-origin: math.random(100) -
                    50 +
                    math.floor(math.div($i, 2)) *
                    10% -
                    $row *
                    100%
                    math.random(100) -
                    50 +
                    $row *
                    50%;
                animation: fly#{$key}
                    5000ms
                    $i *
                    40ms
                    cubic-bezier(0.36, 0.1, 0.16, 1)
                    infinite
                    alternate;

                @keyframes fly#{$key} {
                    $initX: math.random(1000) - 500;
                    $initY: math.random(1000) - 500;
                    $initZ: math.random(1000) - 500;
                    $initDepth: math.random(3000) - 2500;
                    0% {
                        opacity: 0;
                        transform: translate(-50%, -50%) rotateX(#{$initX}deg)
                            rotateY(#{$initY}deg) rotateZ(#{$initZ}deg)
                            translateZ(#{$initDepth}px);
                    }
                    10% {
                        opacity: 0;
                        transform: translate(-50%, -50%) rotateX(#{$initX}deg)
                            rotateY(#{$initY}deg) rotateZ(#{$initZ}deg)
                            translateZ(#{$initDepth}px);
                    }
                    90% {
                        opacity: 1;
                        transform: translate(-50%, -50%) rotate(0deg)
                            rotateY(0deg) rotateZ(0deg) translateZ(0px);
                    }
                    100% {
                        opacity: 1;
                        transform: translate(-50%, -50%) rotate(0deg)
                            rotateY(0deg) rotateZ(0deg) translateZ(0px);
                    }
                }
            }
        }
    }
}
