html {
    scroll-behavior: smooth;
}

.MuiTimelineItem-missingOppositeContent {
    &:before {
        display: none;
        flex: 0;
    }
}
